import React from "react";
import "./WhyUs.css";
import Section from "../Section/Section";
import Card from "../Card/Card";
import graphicOne from "../../images/graphic2.svg";
import graphicTwo from "../../images/analys1.svg";
import graphicThree from "../../images/clie1.svg";
import cosmonaut from "../../images/cosmonaut.png";
import {TranslationContext} from "../../utils/context/translations";

const WhyUs = () => {
    const translations = React.useContext(TranslationContext).whyUs;

    return(
        <Section className="why-us" size="extra" title={translations.title} titleSize="big">
            <div className="why-us__cards-container">
                <div className="why-us__cards-row">
                    <Card size="small" borderColor="yellow" image={graphicOne} text={translations.smallCardsText[0]} />
                    <Card size="small" borderColor="purple" image={graphicTwo} text={translations.smallCardsText[1]} />
                </div>
                <Card size="all-available" borderColor="blue" image={graphicThree} text={translations.bigCardText} />
            </div>

            <img className="cosmonaut" src={cosmonaut} alt="Изображение космонавта, катающегося на серфе" />
        </Section>
    )
};

export default WhyUs;
