import React from 'react';

export const translations = {
    ru: {
        navigation: {
            services: "Услуги",
            faq: "FAQ",
        },
        promo: {
            title: "ХОТИТЕ ПОКУПАТЬ ",
            yellowSpan: "ТРАФИК ",
            title2: "ЧЕРЕЗ CPA-СЕТИ И НЕ ЗНАЕТЕ С ЧЕГО НАЧАТЬ?",
            yellowSpan2: "",
            subtitle: "Упакуем ваш бизнес в СРА-оффер и сопроводим до получения первых продаж",
            btnSignUp: "Получить консультацию",
        },
        promo2: {
            title: "Наша команда сопроводит вас ",
            yellowSpan: "на всех этапах",
            title2: ", до получения первых продаж из ",
            yellowSpan2: "СРА-сетей",
            subtitle: "",
            btnSignUp: "Получить консультацию",
        },
        difficulties: {
            title: "Сложности покупки трафика из СРА-сетей для рекламодателя",
            items: [
                "Выбор СРА-сети, подходящей для конкретного бизнеса",
                "Интеграция CRM-системы с CPA-сетью",
                "Передача конверсий в партнёрскую сеть с помощью POSTBACK",
                "Анализ качества трафика от партнёров"
            ]
        },
        services: {
            title: "МЫ ПОМОГАЕМ",
            tables: [
                {
                    title: "Рекламным агентствам",
                    params: [
                        "Создадим индивидуальное решение или настроим существующие сервисы под ключ (affise, altercpa, offer18)",
                        "Подключим СРА-сети для получения трафика от разных партнёров",
                        "Настроим аналитику качества трафика по партнёрам",
                    ]
                },
            ],
            btnSignUp: "Рассчитать стоимость"
        },
        whyUs: {
            title: "Почему выбирают нас?",
            smallCardsText: [
                "Более 7 лет опыта в области разработки решений для трафика",
                "Бесплатная консультация по вашему проекту",
            ],
            bigCardText: "Средняя продолжительность сотрудничества клиентов с нами более 3-ех лет"
        },
        faq: {
            title: "Сложности покупки трафика из СРА-сетей для рекламодателя",
            accordions: [
                {
                    title: "Выбор СРА-сети, подходящей для конкретного бизнеса",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                },
                {
                    title: "Интеграция CRM-системы с CPA-сетью",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                },
                {
                    title: "Передача конверсий в партнёрскую сеть с помощью POSTBACK",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                },
                {
                    title: "Анализ качества трафика от партнёров",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                },
            ]
        },
        partners: {
            title: "Мы работаем с"
        },
        signUp: {
            title: "Запись на консультацию",
            formTitle: "Заполните форму и мы обязательно свяжемся с вами в ближайшее время",
            inputNamePlaceholder: "Ваш никнейм",
            btnSignUp: "Начать сотрудничество",
        },
        footer: {
            copyright: `© Affmid, ${new Date().getFullYear()}. ВСЕ ПРАВА ЗАЩИЩЕНЫ`
        }
    },
    en: {
        navigation: {
            services: "Services",
            faq: "FAQ",
        },
        promo: {
            title: "Performance advertising ",
            yellowSpan: "infrastructure ",
            title2: "solutions ",
            yellowSpan2: "",
            subtitle: "We help advertisers, media buying teams, and affiliate networks to do the heavy lifting about the infrastructure setup.",
            btnSignUp: "Let`s talk",
        },
        services: {
            title: "HOW WE HELP",
            tables: [
                {
                    title: "In media buying and arbitrage:",
                    params: [
                        "Landing/prelanding pages creating and copying",
                        "WebView apps development for gambling, dating, e-commerce",
                        "Traffic analysis and consulting how to optimize your ad campaigns",
                        "Browser extension development for your media buying purposes",
                        "White pages design services",
                        "Performance marketing trackers setup and consulting",
                    ]
                },
                {
                    title: "With the development and infrastructure",
                    params: [
                        "Software development (data parsing scripts, conversion pixels, order management system)",
                        "Database development",
                        "Custom software development",
                        "CRM data migration",
                        "Performance tracker data migration",
                        "Adjustments and ongoing support",
                    ]
                },
                {
                    title: "With the design services",
                    params: [
                        "Logo/brand book design",
                        "Banner ads design",
                        "Presentation/workbook design",
                        "Site layout in Figma",
                    ]
                },
                {
                    title: "Advertisers",
                    params: [
                        "Integrating your products with affiliate networks",
                        "Integrating your CRM with affiliate networks",
                        "Postback module creating and setup",
                        "Affiliate network development from scratch",
                        "Performance marketing trackers setup and consulting",
                    ]
                }
            ],
            btnSignUp: "Calculate costs"
        },
        whyUs: {
            title: "Why choose us?",
            smallCardsText: [
                "9 years of experience in performance advertising and media buying",
                "Free initial consulting",
            ],
            bigCardText: "The average length of time our customers work with us is more than 1 year."
        },
        faq: {
            title: "FAQ",
            accordions: [
                {
                    title: "How to start?",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                },
                {
                    title: "How long does it take to set up an affiliate network?",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                },
                {
                    title: "I need a custom platform or software.",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                },
                {
                    title: "I already have a project and look for technical support.",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                },
            ]
        },
        partners: {
            title: "We work with"
        },
        signUp: {
            title: "Schedule a free consultation",
            formTitle: "Please leave your messenger ID and we'll get back to you",
            inputNamePlaceholder: "Your nickname",
            btnSignUp: "Start working together",
        },
        footer: {
            copyright: `© Affmid, ${new Date().getFullYear()}. All rights is reserved`
        }
    },
};

export const TranslationContext = React.createContext(translations);