import React from "react";
import "./HowItWorks.css";
import Section from "../Section/Section";

const HowItWorks = () => {
    return(
        <Section className="how-it-works" size="extra" title="Как работают CPA-сети" titleSize="big">

            <div className="round-items__items-container">
                <div className="round-items__item-container">
                    <p className="round-items__item-title">Рекламодатель</p>
                    <p className="round-items__item-description">Размещает свой товар в CPA-сети и платит фиксированное вознаграждение</p>
                </div>

                <div className="round-items__arrow"></div>

                <div className="round-items__item-container">
                    <p className="round-items__item-title">CPA-сеть</p>
                    <p className="round-items__item-description">Берет процент с каждой продажи</p>
                </div>

                <div className="round-items__arrow"></div>

                <div className="round-items__item-container">
                    <p className="round-items__item-title">Вебмастер</p>
                    <p className="round-items__item-description">Дает трафик на товары рекламодателя и получает фиксированное вознаграждение</p>
                </div>
            </div>
        </Section>
    )
}

export default HowItWorks;