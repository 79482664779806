import React from "react";
import "./Footer.css";
import Header from "../Header/Header";
import {Link} from "react-router-dom";

const Footer = (props) => {
    return(
        <footer className="footer">
            <Header place="bottom" currentLang={props.currentLang} onChangeLocalization={props.onChangeLocalization} />
            <div className="footer__section">
                <div className="footer__links-column">
                    <div className="footer__links-heading">Контакты</div>
                    <ul className="footer__links-list">
                        <li className="footer__links-item">
                            <a className="footer__link" href="https://t.me/affmidsupport">Telegram</a>
                        </li>

                        <li className="footer__links-item">
                            <a className="footer__link" href="mailto:hi@affmid.com">hi@affmid.com</a>
                        </li>
                    </ul>
                </div>
                <div className="footer__links-column">
                    <div className="footer__links-heading">© Affmid, {new Date().getFullYear()}</div>
                    <ul className="footer__links-list">
                        <li className="footer__links-item">
                            <Link className="footer__link" to="/privacy">Политика конфиденциальности</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;