import React from "react";
import './App.css';
import Header from "../Header/Header";
import Promo from "../Promo/Promo";
import Services from "../Services/Services";
import WhyUs from "../WhyUs/WhyUs";
import Difficulties from "../Difficulties/Difficulties";
import Footer from "../Footer/Footer";
import HowItWorks from "../HowItWorks/HowItWorks";
import { TranslationContext } from "../../utils/context/translations";
import {Route, Routes} from "react-router-dom";
import Main from "../Main/Main";
import Privacy from "../Privacy/Privacy";

function App() {
    // Данные попапа "бургер-меню"
    const [popupStatus, changePopupStatus] = React.useState({
        burgerMenu: false,
    });

    function handleOpenBurgerMenu() {
        changePopupStatus({
            ...popupStatus,
            burgerMenu: true,
        });
    }

    // Данные секции "Services"
    const [isServicesBlockShown, setIsServicesBlockShown] = React.useState(false);
    const servicesSection = React.useRef();

    function handleServicesScroll() {
        const sectionRects = servicesSection.current.getBoundingClientRect();
        const sectionTop = sectionRects.top + window.pageYOffset;
        const sectionBottom = sectionRects.bottom + window.pageYOffset;

        if (window.pageYOffset + window.innerHeight >= sectionTop && window.pageYOffset <= sectionBottom) {
            setIsServicesBlockShown(true);
        }
    }

    // Локализация
    const [currentLang, setCurrentLang] = React.useState("ru");
    const translations = React.useContext(TranslationContext);

    // Смена языка
    function changeLocalization() {
        if (currentLang === "ru") {
            setCurrentLang("en")
        } else if (currentLang === "en") {
            setCurrentLang("ru")
        }
    }

    return (
        <TranslationContext.Provider value={translations[currentLang]}>
            <div className="app">
                <Routes>
                    <Route path="/" element={<Main handleOpenBurgerMenu={handleOpenBurgerMenu} changeLocalization={changeLocalization} currentLang={currentLang} />}/>
                    <Route path="/privacy" element={<Privacy />}/>
                </Routes>
            </div>
        </TranslationContext.Provider>
    );
}

export default App;
