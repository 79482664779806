import React from "react";
import "./Button.css";
import {HashLink} from "react-router-hash-link";
import {Link} from "react-router-dom";

const Button = (props) => {
    return(
        <>
            {
                props.link

                &&

                <Link className={`${props.className} button button_type_${props.type}`} to={props.link} onClick={() => { props.onClickHandler && props.onClickHandler(props)}}>{props.children}</Link>
            }

            {
                props.anchorLink

                &&

                <HashLink className={`${props.className} button button_type_${props.type}`} to={props.anchorLink} onClick={() => { props.onClickHandler && props.onClickHandler(props)}}>
                    {props.children}
                </HashLink>
            }

            {
                !props.anchorLink

                &&

                !props.link

                &&

                <button className={`${props.className} button button_type_${props.type}`} onClick={() => { props.onClickHandler && props.onClickHandler(props)}}>
                    {props.children}
                </button>
            }
        </>
    )
};

export default Button;