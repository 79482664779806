import React from "react";
import "./List.css";

const List = (props) => {
    return(
        <>
            <h3 className="list__title">{props.config.title}</h3>
            <ul className={`list ${props.type && `list_type_${props.type}`}`}>
                {props.config.items.map((item, index) => {
                    return(
                        <li className="list__item" key={index}>{item}</li>
                    )
                })}
            </ul>
        </>
    )
}

export default List;