import React from "react";
import "./Header.css";
import Logo from "../Logo/Logo";

const Header = (props) => {
    return(
        <header className="header">
            <div className="header__container">
                <Logo />

                <div className="header__contacts">
                    <p className="header__work-hours">Мы работаем с 10:00 до 18:00</p>
                    <a className="header__phone-number" href="tel:+79057053111">+7-905-705-31-11</a>
                </div>
            </div>
        </header>
    )
}

export default Header;
