import React from "react";
import "./Card.css";

const Card = (props) => {
    return(
        <div className={`card card_border-color_${props.borderColor} card_size_${props.size}`}>
            <div className="card__image-container">
                <img className="card__image" src={props.image} alt="Описательное изображение к карточке" />
            </div>
            <p className="card__text">{props.text}</p>
        </div>
    )
};

export default Card;