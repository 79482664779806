import React from "react";
import "./Logo.css";
import logoPicture from "../../images/logo__icon.svg";

const Logo = () => {
    return(
        <div className="logo">
            <img className="logo__picture" src={logoPicture} alt="Логотип сайта-портфолио" />
            <h1 className="logo__title">Affmid</h1>
        </div>
    )
}

export default Logo;
