import React from "react";
import "./Section.css"

const Section = (props) => {
    return(
        <section id={props.id} className={`section`} ref={props.refer}>
            <div className={`${props.className} section__container section__container_size_${props.size}`} style={props.style}>
                {props.title
                &&
                <h2 className={`section__title section__title_size_${props.titleSize} ${props.titleAlign ? `section__title_align_${props.titleAlign}` : ""}`}>{props.title}</h2>
                }

                {props.children}
            </div>
        </section>
    )
};

export default Section;
