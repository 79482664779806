import React from "react";
import "./Promo.css";
import Section from "../Section/Section";
import Button from "../Button/Button";

const Promo = (props) => {
    return(
        <Section className="promo" size="extra" style={props.styles}>
            <h2 className="promo__title">
                {props.title}<span className="span-additional-color span-white-background">{props.yellowSpan}</span>{props.title2}<span className="span-additional-color span-white-background">{props.yellowSpan2}</span>
            </h2>
            {
                props.subtitle

                &&

                <p className="promo__subtitle">
                    {props.subtitle}
                </p>
            }
            <Button className="promo__btn-sign-up" type="primary" link={"https://t.me/affmidsupport"}>{props.btnSignUp}</Button>
        </Section>
    )
};

export default Promo;