import React from "react";
import "./Difficulties.css";
import Section from "../Section/Section";
import { TranslationContext } from "../../utils/context/translations";
import List from "../List/List";

const Difficulties = () => {
    const translations = React.useContext(TranslationContext).difficulties;

    return(
        <Section className="difficulties" size="extra" title={translations.title} titleSize="unique">

            <List config={{
                title: null,
                items: translations.items,
            }}/>
        </Section>
    )
}

export default Difficulties;