import React from "react";
import Header from "../Header/Header";
import Promo from "../Promo/Promo";
import HowItWorks from "../HowItWorks/HowItWorks";
import Difficulties from "../Difficulties/Difficulties";
import WhyUs from "../WhyUs/WhyUs";
import Services from "../Services/Services";
import Footer from "../Footer/Footer";
import {TranslationContext} from "../../utils/context/translations";

const Main = (props) => {
    const translations = React.useContext(TranslationContext);

    return (
        <>
            <Header onOpenBurgerMenu={props.handleOpenBurgerMenu} currentLang={props.currentLang}
                    onChangeLocalization={props.changeLocalization}/>
            <Promo title={translations.promo.title}
                   yellowSpan={translations.promo.yellowSpan}
                   title2={translations.promo.title2}
                   yellowSpan2={translations.promo.yellowSpan2}
                   subtitle={translations.promo.subtitle}
                   btnSignUp={translations.promo.btnSignUp}
            />
            <HowItWorks/>
            <Difficulties/>
            <Promo title={translations.promo2.title}
                   yellowSpan={translations.promo2.yellowSpan}
                   title2={translations.promo2.title2}
                   yellowSpan2={translations.promo2.yellowSpan2}
                   subtitle={translations.promo2.subtitle}
                   btnSignUp={translations.promo2.btnSignUp}
                   styles={{marginTop: "40px"}}
            />
            <WhyUs/>
            <Services />
            {/*<HowWeHelp />*/}
            {/*<Partners/>*/}
            {/*<SigningUp/>*/}
            <Footer currentLang={props.currentLang} onChangeLocalization={props.changeLocalization} />
            {/*<BurgerMenuPopup isOpened={popupStatus.burgerMenu} onClose={handleCloseAllPopups}*/}
            {/*                 currentLang={currentLang} onChangeLocalization={changeLocalization}/>*/}
        </>
    )
}

export default Main;