import React from "react";
import "./Services.css";
import Section from "../Section/Section";
import {TranslationContext} from "../../utils/context/translations";
import List from "../List/List";

const Services = (props) => {
    const translations = React.useContext(TranslationContext).services;

    return (
        <Section id="services" className="services" size="extra" refer={props.servicesSection}
                 onScroll={props.handleServicesScroll}>
            <div className="services__container">
                <h2 className="services__title">{translations.title}</h2>
                {
                    <>
                        {translations.tables.map((table, index) => {
                            return (
                                <List key={index} type="services" config={{
                                    title: table.title,
                                    items: table.params,
                                }}/>
                            )
                        })}
                    </>
                }
            </div>
        </Section>
    )
};

export default Services;