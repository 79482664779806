import React from "react";

const Privacy = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>Политика конфиденциальности</span>
            </p>
            <p style={{textAlign: "center"}}>
                (актуальная версия от 01.10.2023)
            </p>
            <p>
                Настоящая политика обработки персональных данных составлена в соответствии с
                требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» (далее
                — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по
                обеспечению безопасности персональных данных, предпринимаемые Фоменковым Юрием Игоревичем (далее
                — Оператор).
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>1. Общие положения </span>
            </p>
            <p>
                1.1. Оператор ставит своей важнейшей целью и
                условием осуществления своей деятельности соблюдение прав и
                свобод человека и
                гражданина при обработке его персональных данных.
            </p>
            <p>
                1.2. Настоящая политика Оператора в
                отношении обработки персональных данных (далее
                — Политика) применяется ко
                всей информации, которую Оператор может получить о
                посетителях веб-сайта&nbsp;
                https://t.me/affmidsupport.
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>2. Основные понятия, используемые в</span>
                <span style={{fontWeight: "bold"}}>&nbsp;</span>
                <span style={{fontWeight: "bold"}}>Политике</span>
            </p>
            <p>
                2.1. Автоматизированная обработка персональных данных
                — обработка персональных данных с
                помощью средств вычислительной техники.
            </p>
            <p>
                2.2. Блокирование персональных данных
                — временное прекращение обработки персональных данных (за
                исключением случаев, если обработка необходима для уточнения персональных данных).
            </p>
            <p>
                2.3. Веб-сайт
                — совокупность графических и
                информационных материалов, а
                также программ для ЭВМ и
                баз данных, обеспечивающих их
                доступность в
                сети интернет по
                сетевому адресу
                https://t.me/affmidsupport.
            </p>
            <p>
                2.4. Информационная система персональных данных
                — совокупность содержащихся в
                базах данных персональных данных и
                обеспечивающих их
                обработку информационных технологий и
                технических средств.
            </p>
            <p>
                2.5. Обезличивание персональных данных
                — действия, в
                результате которых невозможно определить без использования дополнительной информации принадлежность
                персональных данных конкретному Пользователю или иному субъекту персональных данных.
            </p>
            <p>
                2.6. Обработка персональных данных
                — любое действие (операция) или совокупность действий (операций), совершаемых с
                использованием средств автоматизации или без использования таких средств с
                персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
                обезличивание, блокирование, удаление, уничтожение персональных данных.
            </p>
            <p>
                2.7. Уничтожение персональных данных
                — любые действия, в
                результате которых персональные данные уничтожаются безвозвратно с
                невозможностью дальнейшего восстановления содержания персональных данных в
                информационной системе персональных данных и/или
                уничтожаются материальные носители персональных данных.
            </p>
            <p>
                2.8. Персональные данные
                — любая информация, относящаяся прямо или косвенно к
                определенному или определяемому Пользователю веб-сайта
                https://t.me/affmidsupport.
            </p>
            <p>
                2.9. Персональные данные, разрешенные субъектом персональных данных для распространения,
                — персональные данные, доступ неограниченного круга лиц к
                которым предоставлен субъектом персональных данных путем дачи согласия на
                обработку персональных данных, разрешенных субъектом персональных данных для распространения в
                порядке, предусмотренном Законом о
                персональных данных (далее
                — персональные данные, разрешенные для распространения).
            </p>
            <p>
                2.10. Пользователь
                — любой посетитель веб-сайта&nbsp;
                <a href="https://t.me/affmidsupport" style={{textDecoration: "none"}}>
                    <span style={{color: "#FAD700"}}>https://t.me/affmidsupport</span>
                </a>
                , человек использовавший специальное программное обеспечение в мессенджере «Telegram»&nbsp;
                <a href="https://t.me/affmidsupport" target="_blank" style={{textDecoration: "none"}}>
                    <span style={{color: "#FAD700"}}>https://t.me/affmidsupport</span>
                </a>
                , или обратившийся к Оператору за оказанием услуги по адресу&nbsp;
                <a href="https://t.me/affmidsupport" target="_blank" style={{textDecoration: "none"}}>
                    <span style={{color: "#FAD700"}}>https://t.me/affmidsupport</span>
                </a>
                .
            </p>
            <p>
                2.11. Предоставление персональных данных
                — действия, направленные на
                раскрытие персональных данных определенному лицу или определенному кругу лиц.
            </p>
            <p>
                2.12. Распространение персональных данных
                — любые действия, направленные на
                раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на
                ознакомление с
                персональными данными неограниченного круга лиц, в
                том числе обнародование персональных данных в
                средствах массовой информации, размещение в
                информационно-телекоммуникационных сетях или предоставление доступа к
                персональным данным каким-либо иным способом.
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>3. Основные права и</span>
                <span style={{fontWeight: "bold"}}>&nbsp;</span>
                <span style={{fontWeight: "bold"}}>обязанности Оператора</span>
            </p>
            <p>
                <span style={{fontWeight: "bold"}}>3.1. Оператор имеет право:</span>
            </p>
            <p>
                —
                получать от
                субъекта персональных данных достоверные информацию и/или
                документы, содержащие персональные данные;
            </p>
            <p>
                —
                самостоятельно определять состав и
                перечень мер, необходимых и
                достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о
                персональных данных и
                принятыми в
                соответствии с
                ним нормативными правовыми актами, если иное не
                предусмотрено Законом о
                персональных данных или другими федеральными законами.
            </p>
            <p>
                <span style={{fontWeight: "bold"}}>3.2. Оператор обязан:</span>
            </p>
            <p>
                —
                предоставлять субъекту персональных данных по
                его просьбе информацию, касающуюся обработки его персональных данных;
            </p>
            <p>
                —
                организовывать обработку персональных данных в
                порядке, установленном действующим законодательством
                РФ;
            </p>
            <p>
                —
                отвечать на
                обращения и
                запросы субъектов персональных данных и
                их
                законных представителей в
                соответствии с
                требованиями Закона о
                персональных данных;
            </p>
            <p>
                —
                сообщать в
                уполномоченный орган по
                защите прав субъектов персональных данных по
                запросу этого органа необходимую информацию в
                течение 10
                дней с
                даты получения такого запроса;
            </p>
            <p>
                —
                публиковать или иным образом обеспечивать неограниченный доступ к
                настоящей Политике в
                отношении обработки персональных данных;
            </p>
            <p>
                —
                принимать правовые, организационные и
                технические меры для защиты персональных данных от
                неправомерного или случайного доступа к
                ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных
                данных, а
                также от
                иных неправомерных действий в
                отношении персональных данных;
            </p>
            <p>
                —
                прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку
                и
                уничтожить персональные данные в
                порядке и
                случаях, предусмотренных Законом о
                персональных данных;
            </p>
            <p>
                —
                исполнять иные обязанности, предусмотренные Законом о
                персональных данных.
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>4. Основные права и</span>
                <span style={{fontWeight: "bold"}}>&nbsp;</span>
                <span style={{fontWeight: "bold"}}>обязанности субъектов персональных данных</span>
            </p>
            <p>
                <span style={{fontWeight: "bold"}}>4.1. Субъекты персональных данных имеют право:</span>
            </p>
            <p>
                —
                получать информацию, касающуюся обработки его персональных данных, за
                исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту
                персональных данных Оператором в
                доступной форме, и
                в
                них не
                должны содержаться персональные данные, относящиеся к
                другим субъектам персональных данных, за
                исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень
                информации и
                порядок ее
                получения установлен Законом о
                персональных данных;
            </p>
            <p>
                —
                требовать от
                оператора уточнения его персональных данных, их
                блокирования или уничтожения в
                случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или
                не
                являются необходимыми для заявленной цели обработки, а
                также принимать предусмотренные законом меры по
                защите своих прав;
            </p>
            <p>
                —
                выдвигать условие предварительного согласия при обработке персональных данных в
                целях продвижения на
                рынке товаров, работ и
                услуг;
            </p>
            <p>
                —
                на
                отзыв согласия на
                обработку персональных данных, а
                также, на
                направление требования о
                прекращении обработки персональных данных;
            </p>
            <p>
                —
                обжаловать в
                уполномоченный орган по
                защите прав субъектов персональных данных или в
                судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;
            </p>
            <p>
                —
                на
                осуществление иных прав, предусмотренных законодательством
                РФ.
            </p>
            <p>
                <span style={{fontWeight: "bold"}}>4.2. Субъекты персональных данных обязаны:</span>
            </p>
            <p>
                —
                предоставлять Оператору достоверные данные о
                себе;
            </p>
            <p>
                —
                сообщать Оператору об
                уточнении (обновлении, изменении) своих персональных данных.
            </p>
            <p>
                4.3. Лица, передавшие Оператору недостоверные сведения о
                себе, либо сведения о
                другом субъекте персональных данных без согласия последнего, несут ответственность в
                соответствии с
                законодательством
                РФ.
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>5. Принципы обработки персональных данных</span>
            </p>
            <p>
                5.1. Обработка персональных данных осуществляется на
                законной основе.
            </p>
            <p>
                5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных договором
                или законом. Не
                допускается обработка персональных данных, несовместимая с
                целями сбора персональных данных.
            </p>
            <p>
                5.3. Не
                допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в
                целях, несовместимых между собой.
            </p>
            <p>
                5.4. Обработке подлежат только персональные данные, которые отвечают целям их
                обработки.
            </p>
            <p>
                5.5. Содержание и
                объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не
                допускается избыточность обрабатываемых персональных данных по
                отношению к
                заявленным целям их
                обработки.
            </p>
            <p>
                5.6. При обработке персональных данных обеспечивается точность персональных данных, их
                достаточность, а
                в
                необходимых случаях и
                актуальность по
                отношению к
                целям обработки персональных данных. Оператор принимает необходимые меры и/или
                обеспечивает их
                принятие по
                удалению или уточнению неполных или неточных данных.
            </p>
            <p>
                5.7. Хранение персональных данных осуществляется в
                форме, позволяющей определить субъекта персональных данных, не
                дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не
                установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по
                которому является субъект персональных данных. Обрабатываемые персональные данные уничтожаются либо
                обезличиваются по
                достижении целей обработки или в
                случае утраты необходимости в
                достижении этих целей, если иное не
                предусмотрено федеральным законом.
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>6. Цели обработки персональных данных</span>
            </p>
            <table cellSpacing="0" cellPadding="0"
                   style={{marginBottom: 0, border: "0.75pt solid #d8d8d8", borderCollapse: "collapse"}}>
                <tbody>
                <tr>
                    <td style={{width: "132pt", borderRight: "0.75pt solid #d8d8d8", borderBottom: "0.75pt solid #d8d8d8", padding: "6pt 8.62pt", verticalAlign: "top"}}>
                        <p >
                            Цель обработки
                        </p>
                    </td>
                    <td style={{borderTop: "0.75pt solid #d8d8d8", borderRight: "0.75pt solid #d8d8d8", padding: "6pt 8.62pt", verticalAlign: "top"}}>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            заключение, исполнение и
                            прекращение гражданско-правовых договоров, оплата и исполнение услуг по гражданско-правовым
                            договорам.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style={{width: "132pt", borderTop: "0.75pt solid #d8d8d8", borderRight: "0.75pt solid #d8d8d8", borderBottom: "0.75pt solid #d8d8d8", padding: "6pt 8.62pt", verticalAlign: "top"}}>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            Персональные данные
                        </p>
                    </td>
                    <td style={{borderTop: "0.75pt solid #d8d8d8", borderLeft: "0.75pt solid #d8d8d8", borderBottom: "0.75pt solid #d8d8d8", padding: "6pt 8.62pt", verticalAlign: "top"}}>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            фамилия, имя;
                        </p>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            <span>дата рождения;</span>
                        </p>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            <span>пол;</span>
                        </p>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            <span>данные о гражданстве;</span>
                        </p>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            данные заграничных паспортов;
                        </p>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            электронный адрес.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style={{width: "132pt", borderTop: "0.75pt solid #d8d8d8", borderRight: "0.75pt solid #d8d8d8", borderBottom: "0.75pt solid #d8d8d8", padding: "6pt 8.62pt", verticalAlign: "top"}}>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            Правовое основание
                        </p>
                    </td>
                    <td style={{borderTop: "0.75pt solid #d8d8d8", borderLeft: "0.75pt solid #d8d8d8", borderBottom: "0.75pt solid #d8d8d8", padding: "6pt 8.62pt", verticalAlign: "top"}}>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            Федеральный закон «Об
                            информации, информационных
                            <span className="mark" style={{color: "2125229", backgroundColor: "#fcf8e3"}}></span>
                            технологиях и
                            о
                            защите информации» от
                            27.07.2006
                            N 149-ФЗ
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style={{width: "132pt", borderTop: "0.75pt solid #d8d8d8", borderRight: "0.75pt solid #d8d8d8", padding: "6pt 8.62pt", verticalAlign: "top"}}>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            Виды обработки персональных данных
                        </p>
                    </td>
                    <td style={{borderTop: "0.75pt solid #d8d8d8", borderRight: "0.75pt solid #d8d8d8", padding: "6pt 8.62pt", verticalAlign: "top"}}>
                        <p style={{textAlign: "justify", lineHeight: "150%", fontSize: "12pt"}}>
                            Сбор, запись, систематизация, накопление, хранение, уничтожение и обезличивание персональных
                            данных
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>&nbsp;</span>
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>7. Условия обработки персональных данных</span>
            </p>
            <p>
                7.1. Обработка персональных данных осуществляется с
                согласия субъекта персональных данных на
                обработку его персональных данных.
            </p>
            <p>
                7.2. Обработка персональных данных необходима для исполнения договора, стороной которого либо
                выгодоприобретателем или поручителем по
                которому является субъект персональных данных, а
                также для заключения договора по
                инициативе субъекта персональных данных или договора, по
                которому субъект персональных данных будет являться выгодоприобретателем или поручителем.
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>8. Порядок сбора, хранения, передачи и</span>
                <span style={{fontWeight: "bold"}}>&nbsp;</span>
                <span style={{fontWeight: "bold"}}>других видов обработки персональных данных</span>
            </p>
            <p>
                8.1. Оператор обеспечивает сохранность персональных данных и
                принимает все возможные меры, исключающие доступ к
                персональным данным неуполномоченных лиц.
            </p>
            <p>
                8.2. Если субъект обработки персональных данных даёт согласие на обработку персональных данных, то
                позволяет Оператору также передавать его данные третьему лицу для исполнения обязательств по
                гражданско-правовому договору заключенному между субъектом обработки и Оператором.
            </p>
            <p>
                8.3. В
                случае выявления неточностей в
                персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору
                уведомления через мессенджер «Telegram» -&nbsp;
                <a href="https://t.me/affmidsupport" style={{textDecoration: "none"}}>
                    <span style={{color: "#FAD700"}}>https://t.me/affmidsupport</span>
                </a>
                &nbsp;с пометкой «Актуализация персональных данных».
            </p>
            <p>
                8.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны
                персональные данные, если иной срок не
                предусмотрен договором или действующим законодательством. Пользователь может в
                любой момент отозвать свое согласие на
                обработку персональных данных, направив Оператору уведомление посредством мессенджера «Telegram» -&nbsp;
                <a href="https://t.me/affmidsupport" style={{textDecoration: "none"}}>
                    <span style={{color: "#FAD700"}}>https://t.me/affmidsupport</span>
                </a>
                &nbsp;с пометкой «Отзыв согласия на обработку персональных данных».
            </p>
            <p>
                8.5. Вся информация, которая собирается сторонними сервисами, в
                том числе платежными системами, средствами связи и
                другими поставщиками услуг, хранится и
                обрабатывается указанными лицами в
                соответствии с
                их
                Пользовательским соглашением и
                Политикой конфиденциальности. Оператор не
                несет ответственность за
                действия третьих лиц.
            </p>
            <p>
                8.6. Установленные субъектом персональных данных запреты на
                передачу (кроме предоставления доступа), а
                также на
                обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для
                распространения, не
                действуют в
                случаях обработки персональных данных в
                государственных, общественных и
                иных публичных интересах, определенных законодательством
                РФ.
            </p>
            <p>
                8.7. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.
            </p>
            <p>
                8.8. Оператор осуществляет хранение персональных данных в
                форме, позволяющей определить субъекта персональных данных, не
                дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не
                установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по
                которому является субъект персональных данных.
            </p>
            <p>
                8.9. Условием прекращения обработки персональных данных может являться достижение целей обработки
                персональных данных, истечение срока действия согласия субъекта персональных данных, отзыв согласия
                субъектом персональных данных или требование о
                прекращении обработки персональных данных, а
                также выявление неправомерной обработки персональных данных.
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>9. Перечень действий, производимых Оператором с</span>
                <span style={{fontWeight: "bold"}}>&nbsp;</span>
                <span style={{fontWeight: "bold"}}>полученными персональными данными</span>
            </p>
            <p>
                9.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
                обезличивание, блокирование, удаление и
                уничтожение персональных данных.
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>10. Сбор и метод применения конфиденциальных данных</span>
            </p>
            <p>
                10.1. Оператор собирает данные посредством автоматизированной электронной формы, расположенной на сайте
                (
                <a href="#" style={{textDecoration: "none"}}>
                    <span style={{color: "#FAD700"}}>https://t.me/affmidsupport</span>
                </a>
                ), а также посредством использования специального программного обеспечения в мессенджере «Telegram» (
                <a href="https://t.me/affmidsupport" target="_blank" style={{textDecoration: "none"}}>
                    <span style={{color: "#FAD700"}}>https://t.me/affmidsupport</span>
                </a>
                ).
            </p>
            <p>
                10.2. Оператор использует данные, переданные ему субъектом персональных данных для заключения,
                исполнение заключённого гражданско-правового договора, а также для оплаты услуг предусмотренных
                заключенным договором.
            </p>
            <p>
                10.3. Взаимодействие (в т.ч. передача персональных данных, заключение гражданско-правового договора и
                оплата услуг) между субъектом персональных данных и Оператором происходит исключительно посредством
                мессенджера «Telegram» (
                <a href="https://t.me/affmidsupport" style={{textDecoration: "none"}}>
                    <span style={{color: "#FAD700"}}>https://t.me/affmidsupport</span>
                </a>
                )..
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>11. Конфиденциальность персональных данных</span>
            </p>
            <p>
                11.1. Оператор и иные лица, получившие доступ к
                персональным данным, обязаны не раскрывать третьим лицам и не
                распространять персональные данные без согласия субъекта персональных данных, если иное не
                предусмотрено федеральным законом или настоящей Политикой конфиденциальности.
            </p>
            <p style={{textAlign: "center"}}>
                <span style={{fontWeight: "bold"}}>12. Заключительные положения</span>
            </p>
            <p>
                12.1. Пользователь может получить любые разъяснения по
                интересующим вопросам, касающимся обработки его персональных данных, обратившись к
                Оператору с помощью мессенджера «Telegram» - <a
                href="https://t.me/affmidsupport">https://t.me/affmidsupport</a>.
            </p>
            <p>
                12.2. В
                данном документе будут отражены любые изменения политики обработки персональных данных Оператором.
                Политика действует бессрочно до
                замены ее
                новой версией.
            </p>
            <p>
                12.3. Актуальная версия Политики в
                свободном доступе расположена в
                сети Интернет по
                адресу&nbsp;
                <a href="https://affmid.com/privacy" style={{textDecoration: "none"}}>
                    <span style={{color: "#FAD700"}}>https://affmid.com/privacy</span>
                </a>
            </p>
        </div>
    )
}

export default Privacy;